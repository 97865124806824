import { environment } from "src/config/environment";
import { IAuthPieceState } from "src/pages/auth-piece";

export const getCaptchaBypass = (
  state: IAuthPieceState
): string | undefined => {
  if (environment.environment === "prd" || state?.captchaBypass == null) {
    return undefined;
  }
  return state?.captchaBypass;
};
