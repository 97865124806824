/* eslint-disable spellcheck/spell-checker */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
import { I18nMultiLanguageDictionary } from "./I18n";

const textSholdBeAtLeast =
  "A senha deve ter pelo menos 6 caracteres, e possuir pelo menos um número";

export const dictionary: I18nMultiLanguageDictionary = {
  pt: {
    confirmationCode: "Token de confirmação",
    usernameMY: "E-mail",
    usernameLATAM: "CPF, E-mail ou Código de Consultora",
    name: "nome",
    country: "país",
    "sign up": "cadastrar",
    login: "acessar",
    Password: "Senha",
    confirm: "confirmar",
    Send: "Continuar",
    Proceed: "Proceder",
    Cancel: "Cancelar",
    Understood: "Entendido",
    Yes: "Sim",
    No: "Não",
    or: "ou",
    "Current password": "Senha atual",
    "New password": "Nova senha",
    "Confirmation code": "Código de confirmação",
    "Confirm password": "Confirmar senha",
    "Confirm new password": "Confirmar senha",
    "Forgot password?": "Esqueceu a senha?",
    "Resend first access email": "Reenviar e-mail de primeiro acesso",
    "Field '###' could not be empty": "O campo '###' deve ser preenchido",
    "Resend first access email ELO": "Reenviar instruções de primeiro acesso",
    "Natura Authentication": "Autenticação Natura",
    "Natura and Avon": "Natura e Avon",
    "parameter 'country' is missing":
      "o parametro 'country' deve ser informado",
    "parameter 'company' is missing":
      "o parametro 'company' deve ser informado",
    "parameter 'language' is missing":
      "o parametro 'language' deve ser informado",
    "parameter 'redirectUri' is missing":
      "o parametro 'redirectUri' deve ser informado",
    "An error was encountered with the requested page":
      "Um erro foi encontrado na página solicitada",
    "Invalid username or password": "Nome de usuário ou senha inválidos",
    "username options must contain at least these options:":
      "o username deve conter, pelo menos, as opções a seguir:",
    "not acceptable username type":
      "username não aceito dentro das configurações estabelecidas",
    "We were unable to process your request. Please try again later":
      "Não foi possível processar sua solicitação. Por favor, tente novamente mais tarde",
    "username not found": "usuário não encontrado",
    "passwords do not match": "as senhas devem ser iguais",
    "The password should have the max length of 15 characters":
      "A senha deve ter no máximo 15 caracteres",
    "create new password": "criar nova senha",
    "User does not have an email address, please contact the service center.":
      "O usuário não possui um e-mail, por favor entre em contato com a central de atendimento.",
    "Instructions for creating a new password have been sent to":
      "As instruções para criar uma nova senha foram enviadas para ",
    "Enter your registered e-mail address and receive instructions to recover your password. If you do not have an email, contact customer service":
      "Digite seu endereço de e-mail registrado e receba instruções para recuperar sua senha. Se você não tiver um e-mail, entre em contato com o atendimento ao cliente",
    "Enter your registered code and receive instructions to recover your password. If you do not have an email, contact customer service":
      "Digite seu código de consultora e receba instruções para recuperar sua senha. Se você não tiver um e-mail, entre em contato com o atendimento ao cliente",
    "Enter your registered code and receive instructions to recover your password. If you do not have an email or cell phone number, contact customer service":
      "Digite seu código de consultora e receba instruções para recuperar sua senha. Se você não tiver um e-mail e nem número de celular, entre em contato com o atendimento ao cliente",
    "Verify if this account belongs to you. Choose how to receive the verification code":
      "Verifique se esta conta pertence a você. Escolha como receber o código de verificação",
    "Verify if this account belongs to you. Choose how to receive your temporary password":
      "Verifique se esta conta pertence a você. Escolha como receber sua senha temporária",
    "You do not recognize that cell phone or e-mail? Contact customer service":
      "Você não reconhece esse celular ou e-mail? Entre em contato com o atendimento ao cliente",
    "Invalid confirmation code or password":
      "Código de confirmação ou senha inválida",
    "Passwords do not match": "As senhas não coincidem",
    "Fill in the fields below to create a new password":
      "Preencha os campos a seguir, para criar uma nova senha",
    "The password must be at least 6 characters long, and contain at least one number info": `${textSholdBeAtLeast}`,
    "The password must be at least 6 characters long, and contain at least one number": `${textSholdBeAtLeast}`,
    "The password don't match to the rules": `${textSholdBeAtLeast}`,
    "Failed to set new password": "Falha ao definir a nova senha",
    "User blocked for over-trying, wait a few minutes and try again":
      "Usuário bloqueado por excesso de tentativas, aguarde alguns minutos e tente novamente",
    "Enter your registered e-mail address and receive your temporary password. If you do not have an email, contact customer service":
      "Digite seu endereço de e-mail cadastrado e receba sua senha temporária. Se você não tiver um e-mail, entre em contato com o atendimento ao cliente",
    "Enter your registered code and receive your temporary password. If you do not have an email, contact customer service":
      "Digite seu código e receba sua senha temporária. Se você não tiver um e-mail, entre em contato com o atendimento ao cliente",
    "Enter your registered code and receive your temporary password. If you do not have an email or cell phone number, contact customer service":
      "Digite seu código e receba sua senha temporária. Se você não tiver um e-mail e nem número de celular, entre em contato com o atendimento ao cliente",
    "The password cannot be changed, please communicate with customer support":
      "A senha não pode ser alterada, por favor comunique-se com a atenção",
    "Empty email":
      "O usuário não possui um endereço de e-mail válido, por favor, comunique-se com atenção para atualizar seus dados",
    "Empty email and cell phone number":
      "O usuário não possui endereço de e-mail ou número de celular válidos, por favor, comunique-se com atenção para atualizar seus dados",
    "Your token has expired, request a new password reset process by clicking the link below":
      "Seu token expirou, solicite um novo processo de redefinição de senha clicando no link abaixo",
    "Wrong token was entered, please check your email or request a new password reset by clicking the link below":
      "O token incorreto foi inserido, verifique seu e-mail ou solicite uma nova redefinição de senha clicando no link abaixo",
    "You already have a validated access, if you have difficulties to enter, request a new password by clicking on the link below":
      "Você já tem um acesso validado, se tiver dificuldades para entrar, solicite uma nova senha clicando no link abaixo",
    "Go back to Login": "Voltar para o Login",
    "Your password was updated with success, please click in the button below to go back to login page":
      "Sua senha foi atualizada com sucesso, por favor clique no botão abaixo para voltar à página de login",
    "An e-mail was sent with your temporary password, please check your email an go back to login page by clicking in the button below":
      "Foi enviado um e-mail com sua senha temporária, verifique seu e-mail e volte para a página de login clicando no botão abaixo",
    "You still not make your first access, check your email to see if you already have an temporary password, or request a new one on the link below":
      "Você ainda não fez seu primeiro acesso, verifique seu e-mail para ver se já possui uma senha temporária ou solicite uma nova no link abaixo",
    "Your password has been successfully updated, please return to the NaturaOn app":
      "Sua senha foi atualizada com sucesso, por favor retorne ao aplicativo NaturaOn",
    "User does not exists": "Usuário não existe",
    "Instructions to create a new password have been sent to the email registered in Natura":
      "As instruções para criar uma nova senha foram enviadas para o e-mail cadastrado na Natura",
    "Instructions to create a new password have been sent to the email registered in Natura and Avon":
      "As instruções para criar uma nova senha foram enviadas para o e-mail cadastrado na Natura e Avon",
    "On this screen you can change your new password.":
      "Nesta tela você pode alterar sua nova senha.",
    "You can now go back to the login page":
      "Agora você pode voltar para a página de login",
    "Minimum one number": "Mínimo um número",
    "Minimum one lowercase letter": "Mínimo de uma letra minúscula",
    "Minimum one uppercase letter": "Mínimo de uma letra maiúscula",
    "Minimum one special character": "Mínimo de um caractere especial",
    "Minimum ### characters": "Mínimo de ### caracteres",
    "Maximum ### characters": "Máximo ### caracteres",
    "Without forbidden words": "Sem palavras proibidas",
    "The password cannot contain the excerpts":
      "A senha não pode conter os trechos",
    "Without sequences": "Sem sequências",
    "The password does not meet all requirements":
      "A senha não atende a todos os requisitos",
    "Your password will expire in ### days. Do you want to exchange now?":
      "Sua senha expirará em ### dias. Deseja trocar agora?",
    "Your new password must meet the following requirements":
      "Sua nova senha deve atender aos seguintes requisitos",
    "Password expired": "Senha expirada",
    "Password expired. You will be redirected to the page to reset your password in ### seconds, or":
      "A senha expirou. Você será redirecionado para a página para redefinir sua senha em ### segundos, ou",
    "click here": "clique aqui",
    "to continue": "para continuar",
    "The password is not valid": "A senha não é válida",
    "The user does not have a valid role":
      "O usuário não tem uma categoria válida",
    "User not found in cognito, please check if the social account email is the same as the elo account email.":
      "Usuário não encontrado no cognito, verifique se o e-mail da conta social é o mesmo da conta elo.",
    "We were unable to process your request. Please try again later.":
      "Não foi possível processar sua solicitação. Tente novamente mais tarde.",
    "Continue with Facebook": "Continuar com o Facebook",
    "Continue with Google": "Continuar com o Google",
    "Registration problems, Contact Support.":
      "Identificamos que existe mais de um registro vinculado ao seu e-mail. Entre em contato com o Atendimento ao Cliente para alterar o e-mail cadastrado ou faça login utilizando seus dados pessoais e senha.",
    "Registration successfully Complete. Click the button below to login.":
      "Cadastro efetuado com sucesso. Click no botão abaixo para efetuar o login.",
    "Enter with":
      "Usando o mesmo endereço de e-mail registrado na Natura e Avon Consultoria de Beleza, você pode fazer login com:",
    "Strengthen your password": "Reforce sua senha",
    "We have noticed that your current password is not strong enough. It is highly recommended that you update your password for the safety of your account.":
      "Notamos que sua senha atual é considerada fraca. Para garantir a segurança da sua conta, recomendamos que atualize sua senha.",
    "Update password": "Atualizar senha",
    "Continue without changing": "Continuar sem alterar",
    "Update your password": "Atualize sua senha",
    "We have noticed that your current password is not strong enough. For the safety of your account, please update your password.":
      "Notamos que sua senha atual é considerada fraca. Para garantir a segurança da sua conta, por favor, atualize sua senha.",
    "Continue with BindID": "Continuar",
    "Choose one of the documents and receive your temporary password.":
      "Escolha um dos documentos e receba sua senha temporária.",
    "We were unable to find your registration details, please contact customer service.":
      "Não conseguimos encontrar seus dados de cadastro, por favor entre em contato com o atendimento.",
    "We need to verify your identity. Select where you want to receive the verification code.":
      "Precisamos verificar sua identidade. Selecione onde deseja receber o código de verificação.",
    "We need to verify your identity. Select your email to receive the verification code.":
      "Precisamos verificar sua identidade. Selecione seu e-mail para receber o código de verificação.",
    "Verify that this account belongs to you. Click where you want to receive your verification code.":
      "Verifique se esta conta pertence a você. Clique onde deseja receber seu código de verificação.",
    "Verify that this account belongs to you. Click on your email to receive your verification code.":
      "Verifique se esta conta pertence a você. Clique no seu e-mail para receber seu código de verificação.",
    "Your password has been updated successfully, click the button below to return to the login page.":
      "Sua senha foi atualizada com sucesso, clique no botão abaixo para retornar à página de login.",
    "We send a code to the email:": "Enviamos um código para o email:",
    "Please verify your email.": "Por favor, verifique seu e-mail.",
    "We send a code to the number:": "Enviamos um código para o número:",
    "Please check your cell phone.": "Por favor, verifique seu celular.",
    verify: "verificar",
    "Resend Code": "Reenviar Código",
    "Invalid code. Please check and try again.":
      "Código inválido. Por favor verifique e tente novamente.",
    "To resend another code, wait the necessary time.":
      "Para reenviar outro código, aguarde o tempo necessário.",
    "First Access": "Primeiro Acesso",
    Loading: "Carregando",
    "Please, wait a moment.": "Aguarde um instante, por favor.",
    "Enter a valid email": "Insira um e-mail válido.",
    "Enter a valid Rut document": "Insira um documento RUT válido.",
    "Enter a valid PPT document": "Insira um documento PPT válido.",
    "Failed to create the link for the user":
      "Falha ao criar o vínculo para o usuário",
    "Social login expired, please try to login again":
      "A sessão expirou, tente fazer login novamente",
  },
  en: {
    confirmationCode: "Confirmation Token",
    usernameMY: "E-mail",
    usernameLATAM: "Code or e-mail",
    "Empty email":
      "The user does not have a valid email address, please communicate carefully to update your data",
    "Empty email and cell phone number":
      "The user does not have a valid email address or cell phone number, please communicate carefully to update your data",
    "The password must be at least 6 characters long, and contain at least one number info":
      "The password must be at least 6 characters long, and contain at least one number",
  },
  es: {
    confirmationCode: "Token de confirmación",
    usernameMY: "E-mail",
    usernameCLrut: "Ingresa su RUT",
    usernameCLemail: "Ingresa su E-mail",
    usernameCLpersoncode: "Cod. de Consultora",
    usernameECcedulaidentificacion: "Céd. de Identificación",
    usernameECemail: "Ingresa su E-mail",
    usernameECpersoncode: "Cod. de Consultora",
    usernamerut: "Ingresa tu RUT",
    usernameppt: "Número PPT",
    usernamecurp: "Ingresa tu CURP",
    usernamecarnetextranjeria: "Carnet de Extranjeria",
    usernamepassport: "Ingresa tu Pasaporte",
    usernamecedulaciudadania: "Cédula de Ciudadanía",
    usernamecedulaextranjeria: "Cédula de Extranjería",
    usernamecedulaidentificacion: "Céd. de Identificación",
    usernameemail: "Ingresa tu E-mail",
    usernamepersoncode: "Cod. de Consultora",
    usernamedni: "Ingresa tu DNI",
    usernamedniarg: "Ingresa tu DNI",
    usernameLATAM: "Código de Consultora o e-mail",
    usernameConsultantData: "Ingresa tus datos de consultor",
    name: "nombre",
    country: "país",
    "sign up": "inscribirse",
    login: "acceso",
    loginCO: "acceder",
    loginMX: "iniciar sesión",
    Password: "Contraseña",
    confirm: "confirmar",
    Send: "Continuar",
    Proceed: "Proceder",
    Cancel: "Cancelar",
    Understood: "Entendido",
    Yes: "Sí",
    No: "No",
    or: "o",
    Verification: "Verificación",
    ContactUsCL: `¿No reconoces este teléfono celular o correo electrónico? Para actualizar tus datos, contacta a nuestra Asistente Virtual por WhatsApp al número +55 11 93038-0000`,
    ContactUsEmailCL: `¿No reconoces este correo electrónico? Para actualizar tus datos, contacta a nuestra Asistente Virtual por WhatsApp al número +55 11 93038-0000`,
    ContactUsEC: `¿No reconoces este teléfono celular o correo electrónico? Para actualizar tus datos, llama al 09 595 90 900 (desde celular) o 45010005 (desde fijos). La llamada aplica cargos`,
    ContactUsEmailEC: `¿No reconoces este correo electrónico? Para actualizar tus datos, llama al 09 595 90 900 (desde celular) o 45010005 (desde fijos). La llamada aplica cargos`,
    ContactUsCO: `¿No reconoces este teléfono celular o correo electrónico? Actualiza tus datos por medio de nuestro Asistente Virtual por WhatsApp al número +55 11 93038-0000`,
    ContactUsEmailCO: `¿No reconoces este correo electrónico? Actualiza tus datos por medio de nuestro Asistente Virtual por WhatsApp al número +55 11 93038-0000`,
    ContactUsMX: "¿No reconoces este teléfono celular o correo electrónico?",
    "Current password": "Contraseña actual",
    "New password": "Contraseña nueva",
    "Confirmation code": "Código de confirmacion",
    "Confirm password": "Confirmar contraseña",
    "Confirm new password": "Confirmar contraseña",
    "Forgot password?": "¿Has olvidado tu contraseña?",
    "Resend first access email": "Reenviar correo electrónico de primer acceso",
    "Field '###' could not be empty": "El campo '###' no puede estar vacío",
    "Resend first access email ELO": "Primer acceso",
    "Natura Authentication": "Autenticación Natura",
    "Natura and Avon": "Natura y Avon",
    "parameter 'country' is missing": "falta el parámetro 'country'",
    "parameter 'company' is missing": "falta el parámetro 'company'",
    "parameter 'language' is missing": "falta el parámetro 'language'",
    "parameter 'redirectUri' is missing": "falta el parámetro 'redirectUri'",
    "An error was encountered with the requested page":
      "Se encontró un error con la página solicitada",
    "Invalid username or password": "Usuario o contraseña invalido",
    "username options must contain at least these options:":
      "Las opciones de nombre de usuario deben contener al menos estas opciones:",
    "not acceptable username type": "tipo de nombre de usuario no aceptable",
    "We were unable to process your request. Please try again later":
      "No pudimos procesar su solicitud. Por favor, inténtelo de nuevo más tarde",
    "username not found": "usuario no encontrado",
    "passwords do not match": "la contraseña y su confirmación no coinciden",
    "The password should have the max length of 15 characters":
      "La contraseña debe tener un máximo de 15 caracteres",
    "The password should have the max length of 256 characters":
      "La contraseña debe tener un máximo de 256 caracteres",
    "create new password": "crear nueva contraseña",
    "User does not have an email address, please contact the service center.":
      "El usuario no tiene una dirección de correo electrónico, comuníquese con el centro de servicio.",
    "Instructions for creating a new password have been sent to":
      "Se han enviado instrucciones para crear una nueva contraseña a",
    "Enter your registered e-mail address and receive instructions to recover your password. If you do not have an email, contact customer service":
      "Ingresa tu dirección de correo electrónico registrada y recibe instrucciones para recuperar tu contraseña. Si no tienes un correo electrónico, comunícate con atención al cliente",
    "Enter your registered code and receive instructions to recover your password. If you do not have an email, contact customer service":
      "Ingresa tu código de consultor y recibe instrucciones para recuperar tu contraseña. Si no tienes un correo electrónico, comunícate con atención al cliente",
    "Enter your registered code and receive instructions to recover your password. If you do not have an email, contact customer service (CS)":
      "Ingresa tu código de consultor y recibe instrucciones para recuperar tu contraseña. Si no tienes un correo electrónico, comunícate al Centro de Atención (CAN)",
    "Enter your registered code and receive instructions to recover your password. If you do not have an email or cell phone number, contact customer service":
      "Ingresa tu código de consultor y recibe instrucciones para recuperar tu contraseña. Si no tienes un correo electrónico o un número de celular, comunícate con atención al cliente",
    "Enter your Consulting code and receive instructions to recover your password. If you do not have an email, contact our line 018000969010 or 6015188505.": `<span>Selecciona con qué quieres identificarte y recibe instrucciones para recuperar tu contraseña en tu correo.</br></br>
          Si no tienes un correo electrónico, actualiza tus datos en <a href="https://www.minegocio.be/actualizar">www.minegocio.be/actualizar</a> o comunícate con nuestro Asistente Virtual <a href="https://wa.me/5511930380000">aquí</a>.`,
    "Enter your consultant code and/or email and receive instructions to recover your password. If you don't have an email, contact the customer service center.":
      "Ingresa tu código de consultor y/o correo electrónico y recibe instrucciones para recuperar o crear tu contraseña. Si no tienes un correo electrónico, comunícate con la central de atención.",
    "0800-80-510 for calls from a landline":
      "0800-80-510 para llamadas desde teléfono fijo",
    "01700-9300 for calls from cell phone":
      "01700-9300 para llamadas desde teléfono celular",
    "Watch a video of the process.": "Ve un video del proceso.",
    "Verify if this account belongs to you. Choose how to receive the verification code":
      "Verifica que esta cuenta te pertenece. Elige cómo recibir el código de verificación",
    "Verify if this account belongs to you. Choose how to receive your temporary password":
      "Haz clic en el botón del medio por el que quieres recibir tu contraseña:",
    "You do not recognize that cell phone or e-mail? Contact customer service":
      "¿No reconoces ese celular o correo electrónico? Comuníquese con la atención al cliente",
    "Invalid confirmation code or password":
      "Código de confirmación o contraseña no válidos",
    "Passwords do not match": "Las contraseñas no coinciden",
    "Fill in the fields below to create a new password":
      "Completa los campos para crear una nueva contraseña",
    "The password must have numeric characters":
      "La contraseña elegida debe tener al menos 1 número. Intente nuevamente.",
    "The password must be at least 6 characters long":
      "La contraseña elegida no tiene el numero de caracteres necesarios. Intente nuevamente.",
    "The password must be at least 6 characters long, and contain at least one number info":
      "La contraseña debe tener al menos 6 caracteres y al menos un número.",
    "The password don't match to the rules":
      "La contraseña debe tener al menos 6 caracteres y al menos un número.",
    "Failed to set new password": "No se pudo establecer una nueva contraseña",
    "User blocked for over-trying, wait a few minutes and try again":
      "Usuario bloqueado por exceso de intentos, espere unos minutos y vuelva a intentarlo",
    "Enter your registered e-mail address and receive your temporary password. If you do not have an email, contact customer service":
      "Ingresa tu dirección de correo electrónico registrada y recibe tu código de verificación. Si no tienes un correo electrónico, comunícate con atención al cliente",
    "Enter your registered code and receive your temporary password. If you do not have an email, contact customer service":
      "Ingresa tu código de consultor y recibe tu código de verificación. Si no tienes un correo electrónico, comunícate con atención al cliente",
    "Enter your registered code and receive your temporary password. If you do not have an email or cell phone number, contact customer service":
      "Ingresa tu código de consultor y recibe tu código de verificación. Si no tienes un correo electrónico o un número de celular, comunícate con atención al cliente",
    "The password cannot be changed, please communicate with customer support":
      "No se puede cambiar la contraseña, favor comunicarse con atención",
    "Empty email":
      "El usuario no tiene una dirección de correo electrónico válida, favor comunicarse com atencion para actualizar su datos",
    "Empty email and cell phone number":
      "El usuario no tiene una dirección de correo electrónico o número de celular válidos, favor comunicarse com atencion para actualizar su datos",
    "Your token has expired, request a new password reset process by clicking the link below":
      "Su token ha caducado, solicite un nuevo proceso de restablecimiento de contraseña haciendo clic en el enlace de abajo",
    "Wrong token was entered, please check your email or request a new password reset by clicking the link below":
      "Se ingresó un token incorrecto, verifique su correo electrónico o solicite un nuevo restablecimiento de contraseña haciendo clic en el enlace de abajo",
    "You already have a validated access, if you have difficulties to enter, request a new password by clicking on the link below":
      "Ya tiene un acceso validado, si tiene dificultades para ingresar, solicite una nueva contraseña haciendo clic en el enlace de abajo",
    "Go back to Login": "Volver a Iniciar sesión",
    "Your password was updated with success, please click in the button below to go back to login page":
      "Tu contraseña se actualizó con éxito, haz clic en el botón de abajo para volver a la página de inicio de sesión",
    "An e-mail was sent with your temporary password, please check your email an go back to login page by clicking in the button below":
      "Se envió un correo electrónico con su código de verificación, verifique su correo electrónico y vuelva a la página de inicio de sesión haciendo clic en el botón de abajo",
    "You still not make your first access, check your email to see if you already have an temporary password, or request a new one on the link below":
      "Aún no realizas tu primer acceso, revisa tu correo electrónico para ver si ya tienes una código de verificación, o solicita una nueva en el siguiente enlace",
    "Your password has been successfully updated, please return to the NaturaOn app":
      "Su contraseña se ha actualizado correctamente, vuelva a la aplicación NaturaOn",
    "User does not exists": "El usuario no existe",
    "User does not exists ELO": `<span>El usuario no existe. <span style="color: #000">Te invitamos a actualizar tus datos lo más pronto posible a través del link arriba</span></span>`,
    "Instructions to create a new password have been sent to the email registered in Natura":
      "Se han enviado instrucciones para crear una nueva contraseña al correo registrado en Natura",
    "Instructions to create a new password have been sent to the email registered in Natura and Avon":
      "Se han enviado instrucciones para crear una nueva contraseña al correo registrado en Natura y Avon",
    "On this screen you can change your new password.":
      "En esta pantalla podrás realizar el cambio de tu nueva contraseña.",
    "Invalid session for the user, session is expired.":
      "Sesión no válida para el usuario, la sesión ha caducado.",
    "Document type": "Tipo de Documento",
    "Select consultant data": "Seleccione los datos del consultor",
    "Choose your ID": "Elige tu identificación",
    "Please choose an option": "Por favor elija una opción",
    "Field 'Consultant code or email' cannot be empty":
      "Campo 'Codigo de consultor o correo' no puede estar vacío",
    "Enter your data according to the type of information selected":
      "Ingresa tus datos de acuerdo al tipo de información seleccionada",
    "You can now go back to the login page":
      "Ahora puede volver a la página de inicio de sesión",
    "Minimum one number": "Mínimo un número",
    "Minimum one lowercase letter": "Mínimo una letra minúscula",
    "Minimum one uppercase letter": "Mínimo una letra mayúscula",
    "Minimum one special character": "Mínimo un caracter especial",
    "Minimum ### characters": "Mínimo ### caracteres",
    "Maximum ### characters": "Máximo ### caracteres",
    "Without forbidden words": "Sin palabras o fragmentos prohibidos",
    "mx:Without forbidden words":
      "No contiene ninguna de las siguientes palabras",
    "The password cannot contain the excerpts":
      "La contraseña no puede contener las partes",
    "Without sequences": "Sin secuencias",
    "The password does not meet all requirements":
      "La contraseña no cumple con todos los requisitos",
    "Your password will expire in ### days. Do you want to exchange now?":
      "Tu contraseña caducará en ### días. ¿Quieres cambiarla ahora?",
    "Your new password must meet the following requirements":
      "Tu nueva contraseña debe cumplir con los siguientes requisitos",
    "Password expired": "Contraseña caducada",
    "Password expired. You will be redirected to the page to reset your password in ### seconds, or":
      "La contraseña expiró. Será redirigido a la página para restablecer tu contraseña en ### segundos, o",
    "click here": "clic aquí",
    "to continue": "para continuar",
    "The password is not valid": "La contraseña no es válida",
    "Registration problems, Contact Support.":
      "Identificamos que existe más de un registro vinculado a su correo electrónico. Contacte la Central de Atención para cambiar el correo electrónico registrado o inicie sesión usando su Código de Consultora.",
    "The user does not have a valid role":
      "El usuario no tiene una categoría válida",
    "co:User not found in cognito, please check if the social account email is the same as the elo account email.": `El correo electrónico con el que intentas ingresar no está registrado en Consultoría de Belleza Natura y Avon.<br><br>Puedes iniciar utilizando tus datos personales y contraseña. Para iniciar sesión sin contraseña, debes utilizar el mismo correo electrónico con el que te registraste en Consultoría de Belleza Natura y Avon.<br><br>Si deseas cambiar tu correo de Consultoría de Belleza, haz clic aquí: <a href="https://cutt.ly/OLYS5EH">https://cutt.ly/OLYS5EH</a>`,
    "cl:User not found in cognito, please check if the social account email is the same as the elo account email.":
      "El correo electrónico con el que intentas ingresar no está registrado en Natura.<br><br>Puedes iniciar utilizando tus datos personales y contraseña. Para iniciar sesión sin contraseña, debes utilizar el mismo correo electrónico con el que te registraste en Natura y Avon.",
    "mx:User not found in cognito, please check if the social account email is the same as the elo account email.":
      "El correo electrónico con el que intentas ingresar no está registrado en Natura.<br><br>Puedes iniciar utilizando tus datos personales y contraseña. Para iniciar sesión sin contraseña, debes utilizar el mismo correo electrónico con el que te registraste en Natura.",
    "pe:User not found in cognito, please check if the social account email is the same as the elo account email.": `El correo electrónico con el que intentas ingresar no está registrado en Consultoría de Belleza Natura y Avon.<br><br>Puedes iniciar utilizando tus datos personales y contraseña. Para iniciar sesión sin contraseña, debes utilizar el mismo correo electrónico con el que te registraste en Consultoría de Belleza Natura y Avon.<br><br>Si deseas cambiar tu correo de Consultoría de Belleza, haz clic aquí: <a href="https://bit.ly/3ALGgIt">https://bit.ly/3ALGgIt</a>`,
    "We were unable to process your request. Please try again later.":
      "No pudimos procesar su solicitud. Vuelva a intentarlo más tarde.",
    "Continue with Facebook": "Continuar con Facebook",
    "Continue with Google": "Continuar con Google",
    "Contact Support.": "Soporte de contacto.",
    "Registration successfully Complete. Click the button below to login.":
      "Registro completado exitosamente.",
    "Enter with":
      "Utilizando el mismo correo electrónico de registro en la Consultoría de Belleza Natura y Avon, puedes iniciar sesión con:",
    "Choose your identity": "Selecciona tu identificación",
    "mx:Choose your identity": "Selecciona tu forma de ingreso",
    "I want to be a Consultant": "Quiero ser Consultora de Belleza",
    "Verify that this account belongs to you. Choose where you want to receive your temporary password.": `Verifica que esta cuenta te pertenece. <br>Elige en dónde deseas recibir tu código de verificación.`,
    "natura:Enter with":
      "Utilizando el mismo correo electrónico registrado en Natura, puedes iniciar sesión con:",
    "The user cannot perform this action":
      "El usuario no puede realizar esta acción",
    "Strengthen your password": "Fortalece tu contraseña",
    "We have noticed that your current password is not strong enough. It is highly recommended that you update your password for the safety of your account.":
      "Hemos notado que tu contraseña no es segura. Para garantizar la seguridad de tu cuenta, te recomendamos actualizar tu contraseña.",
    "Update password": "Actualizar contraseña",
    "Continue without changing": "Seguir sin cambiar",
    "Update your password": "Actualiza tu contraseña",
    "We have noticed that your current password is not strong enough. For the safety of your account, please update your password.":
      "Hemos notado que tu contraseña no es segura. Para garantizar la seguridad de tu cuenta, actualiza tu contraseña.",
    "Choose one of the documents and receive your temporary password.":
      "Elija uno de los documentos y reciba su código de verificación.",
    usernotfoundCO: `No pudimos encontrar tus datos de registro. Por favor actualiza tus datos en <a href="https://www.minegocio.be/actualizar">www.minegocio.be/actualizar</a> o contacta a nuestro Asistente Virtual.`,
    usernotfoundMX: `No pudimos encontrar tus datos de registro. Por favor contacta a nuestra Asistente virtual por WhatsApp al número +55 11 93038-0000 o si deseas actualizar tus datos, da <a href="https://update.registronatura.net/">clic aquí</a>.`,
    "We were unable to find your registration details, please contact customer service.": `No pudimos encontrar tus datos de registro. Por favor contacte a nuestra Asistente Virtual para actualizarlos.`,
    "We need to verify your identity. Select where you want to receive the verification code.": `Verifica que esta cuenta te pertenece. <br> Elige en dónde deseas recibir tu código de verificación.`,
    "We need to verify your identity. Select your email to receive the verification code.": `Verifica que esta cuenta te pertenece. <br> Selecciona tu correo para recibir el código de verificación.`,
    "Verify that this account belongs to you. Click where you want to receive your verification code.":
      "Verifica que esta cuenta te pertenece. Haz clic en donde desees recibir tu código de verificación.",
    "Your password has been updated successfully, click the button below to return to the login page.":
      "Tu contraseña se actualizó con éxito, haz clic en el botón de abajo para volver a la página de inicio de sesión.",
    "We send a code to the email:": "Enviamos un código al correo electrónico:",
    "Please verify your email.": "Por favor, verifica tu correo electrónico.",
    "We send a code to the number:": "Enviamos un código al número:",
    "Please check your cell phone.": "Por favor revisa tu celular.",
    verify: "verificar",
    "Resend Code": "Reenviar Código",
    "Invalid code. Please check and try again.":
      "Código inválido. Revísalo e intenta de nuevo.",
    "To resend another code, wait the necessary time.":
      "Para reenviar otro código, espera el tiempo necesario.",
    "First Access": "Primer Acceso",
    "Continue with BindID": "volver a iniciar sesión",
    "User not found in cognito":
      "Correo electrónico no registrado en Consultoría de Belleza Natura y Avon",
    "mx:User not found in cognito":
      "Correo electrónico no registrado en Natura",
    "Login without password": "Iniciar sesión sin contraseña",
    Loading: "Cargando",
    "Please, wait a moment.": "Por favor, espere un momento.",
    "Enter a valid Rut document": "Ingresa un RUT válido.",
    "Enter a valid email": "Ingresa un correo electrónico válido.",
    "Enter a valid CI document": "Ingresa una cédula de identificación válida.",
    "Enter a valid DNI": "Ingresa un DNI válido.",
    "Expired session.":
      "Su sesión ha expirado. Por favor, regrese a la pantalla inicial y vuelva a intentarlo nuevamente.",
    "Reached maximum invalid tokens.":
      "Se alcanzó el máximo de tokens inválidos. Por favor, regrese a la pantalla inicial y vuelva a intentarlo nuevamente.",
    "Registration not found in the country":
      "Registro no encontrado en el país",
    "Your record was not found in the selected country.":
      "Tu registro no fue encontrado en el país seleccionado.<br>Por favor, vuelve y selecciona el país correcto.",
    "Failed to create the link for the user":
      "No se pudo crear un enlace para el usuario",
    "Session expired, please try to login again":
      "La sesión expiró, intenta iniciar sesión nuevamente.",
    "Request password for the first time":
      "Solicitar contraseña por primera vez",
    "Enter a valid PPT document": "Ingresa un PPT válido.",
  },
};
