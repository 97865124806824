/* eslint-disable spellcheck/spell-checker */
import { Country } from "src/interfaces/country";
import { IConsultantData } from "./consultant-documents-data";

const personCodeEs = {
  key: "personCode",
  value: "Código",
};

const emailEs = {
  key: "email",
  value: "E-mail",
};

const loginDocumentsData = [
  {
    country: "pe",
    data: [
      personCodeEs,
      {
        key: "carnetExtranjeria",
        value: "CE",
      },
      {
        key: "dni",
        value: "DNI",
      },
      emailEs,
      {
        key: "passport",
        value: "Pasaporte",
      },
    ],
  },
  {
    country: "co",
    data: [
      personCodeEs,
      {
        key: "cedulaCiudadania",
        value: "CC",
      },
      {
        key: "cedulaExtranjeria",
        value: "CE",
      },
      emailEs,
      {
        key: "ppt",
        value: "PPT",
      },
    ],
  },
  {
    country: "cl",
    data: [
      personCodeEs,
      emailEs,
      {
        key: "rut",
        value: "RUT",
      },
    ],
  },
  {
    country: "mx",
    data: [
      personCodeEs,
      {
        key: "curp",
        value: "CURP",
      },
      emailEs,
    ],
  },
  {
    country: "ec",
    data: [
      {
        key: "cedulaIdentificacion",
        value: "CI",
      },
      personCodeEs,
      emailEs,
    ],
  },
  {
    country: "ar",
    data: [
      {
        key: "dniArg",
        value: "DNI",
      },
      personCodeEs,
      emailEs,
    ],
  },
] as unknown as IConsultantData[];

const loginDocumentsDataDic = (() => {
  const data: { [id: string]: IConsultantData } = {};
  loginDocumentsData.forEach((doc) => {
    data[doc.country] = doc;
  });
  return data;
})();

export const getLoginDocumentsData = (
  country: string
): IConsultantData | undefined => {
  if (country === undefined || country === "") {
    return undefined;
  }
  const countryLowerCase = country.toLowerCase();
  return loginDocumentsDataDic[countryLowerCase];
};

export const getLoginDefaultSelectValue = (country: Country): string => {
  const localValue = handleStorageDocumentValue(country);
  return localValue ?? (getLoginDocumentsData(country)?.data[0].key as string);
};

export const handleStorageDocumentValue = (country: Country) => {
  const data = getLoginDocumentsData(country);
  let isSelectedOptionInData;
  const localValue = localStorage.getItem("selectedOption");

  if (data !== undefined) {
    isSelectedOptionInData = data.data.some((item) => item.key === localValue);
  }
  if (!isSelectedOptionInData) {
    localStorage.removeItem("selectedOption");
    return undefined;
  }
  return localValue;
};
