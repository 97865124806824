/* eslint-disable spellcheck/spell-checker */
import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Link, Input } from "../components";
import { Container } from "../components/container";
import { isNotEmpty } from "../utils/is-not-empty";
import { redirect } from "../utils/redirect";
import { decideQuerySeparator } from "../utils/query-builder";
import styles from "src/styles/styles";
import { FormHelperText, Select } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { isTooManyRequestsError } from "src/utils/too-many-requests-validator";
import { attemptLimitExceededMessage } from "src/utils/attempt-limit-exceeded-message";
import { ApiError } from "src/interfaces/api-error";
import { environment } from "src/config/environment";
import { SignInResponse } from "src/api/interfaces/sigin-response";
import { getNewPassValidationConfig } from "src/config/parse-password-config";
import {
  eloIsEnabled,
  otpIsEnabled,
  showCountryFlag,
} from "src/utils/elo-utils";
import { PasswordPolicyConfig } from "src/api/interfaces/fetch-password-policy-response";
import { PasswordValidationExpiredModal } from "src/components/password-validation-expired-modal";
import { PasswordValidationUpcomingExpirationModal } from "src/components/password-validation-upcoming-expiration-modal";
import { FIELD_COULD_NOT_BE_EMPTY } from "src/I18n/I18n-templates";
import { SocialLoginScreen } from "src/components/social-login-screen";
import {
  validatePasswordPolicy,
  validatePasswordPolicyByLib,
} from "src/utils/password-validator";
import {
  getLoginDefaultSelectValue,
  getLoginDocumentsData,
  handleStorageDocumentValue,
} from "src/utils/login-documents-data";
import { getConsultantDocumentsSelectList } from "src/utils/consultant-documents-data";
import {
  getHelperTextStyling,
  getLoginButtonStyling,
  getLoginInputStyling,
} from "src/utils/styling-handler";
import ChooseYourIdentityText from "src/components/chooseYourIdentity-info";

export type ISignInProps = IAuthPieceProps;
import Inputmask from "inputmask";
import { validate } from "rut.js";
import { Country } from "src/interfaces/country";
import crypto from "crypto-js";
import { getDeviceOrigin } from "src/utils/device-origin";
import { validateEcuadorDocument } from "src/utils/ecuador-validation";
import { isPastDate } from "src/utils/date-utils";

export interface ISignInState extends IAuthPieceState {
  username?: string;
  password?: string;
  session?: string;
  usernameState?: string;
  usernameHelpText?: string;
  passwordState?: string;
  passwordHelpText?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string;
  passwordExpired?: boolean;
  avoidPasswordValidation?: boolean;
  passwordWillExpire?: boolean;
  signInResult?: SignInResponse;
  passwordExpires?: boolean;
  expirationStartDate?: Date;
  passwordPolicy?: PasswordPolicyConfig[];
  validationStartDate?: Date;
}

const LOAD_PASSWORD_POLICY = false;

export class SignIn extends AuthPiece<ISignInProps, ISignInState> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: ISignInProps) {
    super(props);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
    this.getPasswordFromInput = this.getPasswordFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);

    this.refButton = React.createRef();
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    await this.handleAlreadySignedInUsers();

    this.props.cookies.createOrUpdateCookie("cookieParams", this.state);

    this.loadPasswordExpirationPolicy();
    this.setState({
      consultantDocument: getLoginDefaultSelectValue(this.state?.country),
    });
    if (LOAD_PASSWORD_POLICY) {
      await this.loadPasswordPolicy();
    }
  }

  private loadPasswordExpirationPolicy(): void {
    const passConfig = getNewPassValidationConfig(
      environment.newValidationConfig
    );
    this.setState({
      avoidPasswordValidation: false,
      passwordExpires: passConfig?.passwordExpires,
      expirationStartDate: passConfig?.expirationStartDate,
      consultantDocument: getLoginDefaultSelectValue(this.state?.country),
    });
  }

  private async loadPasswordPolicy(): Promise<void> {
    const country = this.state?.country as string;
    const company = this.state?.company;
    const language = this.state?.language as string;

    try {
      const result = await this.props.api.fetchPasswordPolicyCache({
        country,
        company,
        language,
      });
      this.setState({
        passwordPolicy: result[language]?.[company]?.[country] ?? [],
      });
    } catch (err) {
      const apiError = err as ApiError;
      console.info("Unable to load password rules:", apiError.message);
    }
  }

  public getUsernameFromInput(): string | undefined {
    return this.state.username;
  }

  public getPasswordFromInput(): string | undefined {
    return this.state.password;
  }

  private getSendPassUrlOrNavigationLink(route: string): string {
    const linkRoute = otpIsEnabled(this.state?.country)
      ? "otp-identification"
      : route;
    return this.buildNavigationLink(linkRoute);
  }

  private getFirstAccessText(): string {
    if (otpIsEnabled(this.state?.country)) {
      if (this.state?.country === "co") {
        return "Request password for the first time";
      }
      return "First Access";
    }
    if (eloIsEnabled(this.state?.country)) {
      return "Resend first access email";
    }
    return "Resend first access email";
  }

  private selectConsultantDocumentsData(): React.JSX.Element {
    const country = this.state?.country;
    const data = getLoginDocumentsData(country);

    if (
      !(
        data !== undefined &&
        environment.loginByDocumentCountries?.includes(country)
      )
    ) {
      return <></>;
    }

    const options = getConsultantDocumentsSelectList(data);
    const localValue = handleStorageDocumentValue(country);

    const defualtValue =
      this.state?.consultantDocument ??
      localValue ??
      getLoginDefaultSelectValue(country);
    return (
      <div className="row" style={styles.centerRow}>
        <div style={styles.select}>
          <Select
            defaultValue={defualtValue}
            onChange={(event: ChangeEvent<any>) => {
              this.setState({
                consultantDocument: event.target.value,
                usernameState: "",
                usernameHelpText: "",
              });
              localStorage.setItem("selectedOption", event.target.value);

              this.handleInputMask(this.state.country);
            }}
            options={options}
          />
        </div>
      </div>
    );
  }

  public render(): React.JSX.Element {
    const country = this.state?.country ?? "";
    const enabledConsultantRegistrationCountries =
      environment.consultantRegistrationCountries;

    return (
      <Container
        country={showCountryFlag(this.state?.country, true)}
        company={this.state?.company}
        isLoading={this.state?.isLoading}
        hideBackButton={true}
      >
        <PasswordValidationUpcomingExpirationModal
          visible={this.state?.passwordWillExpire}
          onChoose={(resetPassword) => {
            this.setState({ passwordWillExpire: false });
            this.onChoosePasswordModal(resetPassword);
          }}
          i18n={this.props.i18n}
        />
        <PasswordValidationExpiredModal
          visible={this.state?.passwordExpired}
          onChoose={(resetPassword) => {
            this.setState({ passwordExpired: false });
            this.onChoosePasswordModal(resetPassword);
          }}
          i18n={this.props.i18n}
        />
        <ChooseYourIdentityText
          country={country}
          enabledCountries={environment.loginByDocumentCountries}
        >
          {country === "mx"
            ? this.props.i18n.get(`${country}:Choose your identity`)
            : this.props.i18n.get("Choose your identity")}
        </ChooseYourIdentityText>
        <div className="row" style={styles.centerRow}>
          <div style={getLoginInputStyling(country)}>
            {this.selectConsultantDocumentsData()}
            <Input
              id="username"
              type="text"
              value={this.state?.username ?? ""}
              placeholder={this.props.i18n.getUsernamePlaceholder(
                country,
                (localStorage.getItem("selectedOption") as string) ??
                  (this.state?.consultantDocument as string)
              )}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.handleUsernameOnChange(event, country);
              }}
              onBlur={() =>
                [
                  "rut",
                  "curp",
                  "email",
                  "cedulaIdentificacion",
                  "ppt",
                  "dniArg",
                ].includes(this.state?.consultantDocument as string) &&
                this.validUserTypeInput()
              }
              helpText={this.state?.usernameHelpText ?? ""}
              state={this.state?.usernameState ?? ""}
              onKeyDown={this.handleOnKeyDown}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div className="passwordInput" style={getLoginButtonStyling(country)}>
            <Input
              id="password"
              type="password"
              placeholder={this.props.i18n.get("Password")}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({
                  password: event.target.value,
                  passwordState: "",
                  passwordHelpText: "",
                })
              }
              helpText={this.state?.passwordHelpText ?? ""}
              state={this.state?.passwordState ?? ""}
              onKeyDown={this.handleOnKeyDown}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={getHelperTextStyling(country)}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={getLoginButtonStyling(country)}>
            <Button
              id="signInButton"
              onClick={async (): Promise<void> => {
                await this.onSignInButtonClick(
                  this.state?.avoidPasswordValidation === true
                );
              }}
              text={this.getSignInButtonText(country)}
              itemRef={this.refButton}
              keepEnabled={true}
              style={{ letterSpacing: "1.23px" }}
              disabled={this.state?.usernameState === "error"}
            />
            {enabledConsultantRegistrationCountries.includes(country) && (
              <Button
                style={{ marginTop: "24px", letterSpacing: "1.23px" }}
                variant="outlined"
                text={this.props.i18n
                  .get("I want to be a Consultant")
                  .toUpperCase()}
                onClick={async (): Promise<void> => {
                  this.setState({ isLoading: true });
                  window.location.href = this.getSignUpUrl(country).toString();
                }}
              />
            )}
          </div>
        </div>
        {this.socialLoginScreen()}
        <div style={{ marginTop: "10px" }}>
          <Link
            style={{ fontWeight: "bold" }}
            text={this.props.i18n.get("Forgot password?")}
            url={this.getSendPassUrlOrNavigationLink("forgot-password")}
          />
        </div>
        <Link
          style={{ fontWeight: "bold" }}
          text={this.props.i18n.get(this.getFirstAccessText())}
          url={this.getSendPassUrlOrNavigationLink("resend-temporary-password")}
        />
      </Container>
    );
  }

  private getSignUpUrl(country: Country): URL {
    const countryUrls = {
      mx: environment.signUpUrlMX,
      ar: environment.signUpUrlAR,
      default: environment.signUpUrl,
    };

    const signUpUrl = new URL(
      countryUrls[country as keyof typeof countryUrls] || countryUrls.default
    );
    signUpUrl.searchParams.append("country", country);
    const origin = getDeviceOrigin(this.state.clientId);

    if (origin) {
      signUpUrl.searchParams.append("origin", origin);
    }

    return signUpUrl;
  }

  private getSignInButtonText(country: Country): string {
    let buttonText = this.props.i18n.get("login");

    switch (country) {
      case "mx":
        buttonText = this.props.i18n.get("loginMX");
        break;
      case "co":
        buttonText = this.props.i18n.get("loginCO");
        break;
      default:
        break;
    }

    return buttonText;
  }

  private async onSignInButtonClick(
    avoidPasswordValidation: boolean
  ): Promise<void> {
    try {
      if (!this.validUserInput() || !this.validUserTypeInput()) {
        return;
      }

      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        isLoading: true,
      });

      const { username, password, encryptedPass } = this.getSignInCredentials();
      const signInResult = await this.callSignInAPI(username, encryptedPass);

      if (
        this.shouldValidatePassword(
          signInResult,
          avoidPasswordValidation,
          password
        )
      ) {
        this.handlePasswordValidation();
        return;
      }

      this.loginFlow(signInResult);
    } catch (err) {
      const error = err as Error;
      this.setState({ isLoading: false });
      this.handleSignInError(error);
    }
  }

  private getSignInCredentials() {
    const username = this.state?.username?.trim() as string;
    const password = this.state?.password?.trim() as string;
    const encryptedPass = crypto.AES.encrypt(password, username).toString();
    return { username, password, encryptedPass };
  }

  private async callSignInAPI(
    username: string,
    encryptedPass: string
  ): Promise<SignInResponse> {
    return this.props.api.signIn({
      clientId: this.state?.clientId,
      country: this.state?.country as string,
      company: this.state?.company,
      username,
      password: encryptedPass,
      redirectUrl: this.state.redirectUri,
      document: environment.loginByDocumentCountries?.includes(
        this.state.country
      )
        ? this.state.consultantDocument
        : undefined,
      responseType: this.state?.responseType,
      codeChallenge: this.state?.codeChallenge,
      codeChallengeMethod: this.state?.codeChallengeMethod,
    });
  }

  private shouldValidatePassword(
    signInResult: SignInResponse,
    avoidPasswordValidation: boolean,
    password: string
  ): boolean {
    return (
      !signInResult.challengeRequired &&
      !avoidPasswordValidation &&
      !environment.newPassValidationWhiteList.includes(
        password.toLocaleLowerCase()
      ) &&
      isPastDate(this.state.validationStartDate) &&
      !this.preValidatePassword()
    );
  }

  private handlePasswordValidation() {
    this.setState(
      isPastDate(this.state.expirationStartDate)
        ? { passwordExpired: true }
        : { passwordWillExpire: true }
    );
    this.setState({ isLoading: false });
  }

  private handleSignInError(error: Error): void {
    if (isTooManyRequestsError(error)) {
      this.showPasswordAttemptsExceededError();
    } else if (this.isNotFoundError(error)) {
      this.showNotFoundError();
    } else if (this.isInvalidPasswordError(error)) {
      this.showInvalidPasswordError();
    } else if (!!this.state?.passwordExpires && this.isPasswordExpired(error)) {
      this.setState({ passwordExpired: true });
    } else {
      this.showGenericError();
    }
  }

  private preValidatePassword(): boolean {
    const password = this.state?.password?.replace(/ /g, "") as string;
    if (this.state?.passwordPolicy?.length) {
      return validatePasswordPolicy(password, this.state.passwordPolicy);
    }
    return validatePasswordPolicyByLib(password);
  }

  private async loginFlow(signInResult: SignInResponse): Promise<void> {
    if (signInResult.challengeRequired === true) {
      const state = { ...this.state, session: signInResult.challenge.session };
      this.setState({ session: signInResult.challenge.session });
      this.navigate("first-access", state);
    } else {
      await this.saveUserSession(signInResult.sso_token);
      this.goBackToApplication(signInResult);
    }
  }

  private onChoosePasswordModal(resetPassword?: boolean): void {
    if (resetPassword) {
      this.navigate("change-password", this.state);
    } else {
      this.setState({ avoidPasswordValidation: true });
      this.onSignInButtonClick(true);
    }
  }

  private async handleAlreadySignedInUsers(): Promise<void> {
    const cookieName = this.getCookieName();
    const ssoToken = this.props.cookies.getCookie(cookieName);
    try {
      if (ssoToken !== null && ssoToken !== undefined) {
        this.setState({ isLoading: true });
        await this.props.api.recoverSession(
          ssoToken,
          this.state.redirectUri,
          this.state.clientId
        );
        this.goBackToApplication({
          sso_token: ssoToken,
        } as SignInResponse);
      }
    } catch (error: any) {
      this.setState({ isLoading: false });
      if (
        !(error as Error).message.includes(
          "Invalid clientId and RedirectUrl combination"
        )
      ) {
        this.eraseUserSession();
      }
    }
  }

  private goBackToApplication(signInResponse: SignInResponse): void {
    const querySeparator = decideQuerySeparator(this.state.redirectUri);
    if (signInResponse.code !== undefined) {
      redirect(
        `${this.state.redirectUri}${querySeparator}code=${signInResponse.code}${
          this.state.state !== undefined ? `&state=${this.state.state}` : ""
        }`
      );
    } else {
      redirect(
        `${this.state.redirectUri}${querySeparator}sso_token=${signInResponse.sso_token}`
      );
    }
  }

  private async saveUserSession(ssoToken: string): Promise<void> {
    const cookieName = this.getCookieName();
    this.props.cookies.createCookie(cookieName, ssoToken);
  }

  private eraseUserSession(): void {
    const cookieName = this.getCookieName();
    this.props.cookies.removeCookie(cookieName);
  }

  private getCookieName(): string {
    return `${this.state?.country}_${this.state?.company}_id`.toLowerCase();
  }

  private validUserInput(): boolean {
    let isUserInputValid = true;
    const passsord = this.state?.password?.replace(/ /g, "") as string;
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      isUserInputValid = false;
    }
    if (!isNotEmpty(passsord)) {
      this.setPasswordError();
      isUserInputValid = false;
    }
    if (passsord.length > 256) {
      this.showPasswordsMaxLengthError();
      isUserInputValid = false;
    }
    return isUserInputValid;
  }

  private validUserTypeInput(): boolean {
    const { consultantDocument, username, country } = this.state;

    if (!username) return true;

    const loginByDocumentCountries =
      environment.loginByDocumentCountries.includes(country);

    const validators: Record<string, (username: string) => boolean> = {
      rut: (username) => validate(username) || !loginByDocumentCountries,
      cedulaIdentificacion: (username) =>
        validateEcuadorDocument(username) || !loginByDocumentCountries,
      ppt: (username) => /^\d{10}$/.test(username) || !loginByDocumentCountries,
      dniArg: (username) =>
        /^(?!([0-9])\1{6,7})\d{7,8}$/.test(username) ||
        !loginByDocumentCountries,
      email: (username) =>
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username),
    };

    if (
      (consultantDocument as string) in validators &&
      !validators[consultantDocument as string](username)
    ) {
      return this.setValidationError(consultantDocument as string);
    }

    return true;
  }

  private setValidationError(consultantDocument: string): boolean {
    const errorMessages: Record<string, string> = {
      rut: "Enter a valid Rut document",
      cedulaIdentificacion: "Enter a valid CI document",
      ppt: "Enter a valid PPT document",
      dniArg: "Enter a valid DNI",
      email: "Enter a valid email",
    };

    this.setState({
      usernameState: "error",
      usernameHelpText: this.props.i18n.get(errorMessages[consultantDocument]),
    });

    return false;
  }

  private setUsernameError() {
    this.setState({
      usernameState: "error",
      usernameHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get(
          this.props.i18n.getUsernamePlaceholder(
            this.state?.country,
            this.state?.consultantDocument
          )
        )
      ),
    });
  }

  private setPasswordError() {
    this.setState({
      passwordState: "error", //NOSONAR //pragma: allowlist secret
      passwordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Password")
      ),
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
    if (
      event.currentTarget.id === "username" &&
      this.state?.consultantDocument === "rut" &&
      event.key.toLowerCase() === "backspace"
    ) {
      event.currentTarget.value = event.currentTarget.value.slice(0, -1);
      this.setState({ username: event.currentTarget.value });
    }
  }

  private showGenericError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "We were unable to process your request. Please try again later"
      ),
      formHelperState: "error",
      consultantDocument: getLoginDefaultSelectValue(this.state.country),
    });
  }

  private isInvalidPasswordError(error: Error): boolean {
    return (
      (error as ApiError).message
        .toLowerCase()
        .includes("Incorrect username or password".toLowerCase()) ||
      (error as ApiError).message
        .toLowerCase()
        .includes(
          "it was not possible to recognize username type for value".toLowerCase()
        )
    );
  }

  private isPasswordExpired(error: Error): boolean {
    return (error as ApiError).message
      .toLowerCase()
      .includes("The password has expired".toLowerCase());
  }

  private showInvalidPasswordError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "Invalid username or password"
      ),
      formHelperState: "error",
      consultantDocument: getLoginDefaultSelectValue(this.state.country),
    });
  }

  private showPasswordsMaxLengthError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "The password should have the max length of 256 characters"
      ),
      formHelperState: "error",
    });
  }

  private isNotFoundError(error: Error): boolean {
    return (error as ApiError).status === 404;
  }

  private showNotFoundError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("User does not exists"),
      formHelperState: "error",
      consultantDocument: getLoginDefaultSelectValue(this.state.country),
    });
  }

  private showPasswordAttemptsExceededError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        attemptLimitExceededMessage()
      ),
      formHelperState: "error",
      consultantDocument: getLoginDefaultSelectValue(this.state.country),
    });
  }

  private socialLoginScreen(): React.ReactNode {
    return (
      <SocialLoginScreen
        i18n={this.props.i18n}
        country={this.state?.country}
        clientId={this.state?.clientId}
        cookies={this.props.cookies}
      />
    );
  }
  private handleUsernameOnChange(
    event: React.ChangeEvent<HTMLInputElement>,
    country: Country
  ) {
    const selector = document.getElementById("username") as HTMLInputElement;

    this.setState({
      usernameState: "",
      username: event.currentTarget.value,
      usernameHelpText: "",
    });

    if (!environment.loginByDocumentCountries.includes(country)) {
      return;
    }

    if (this.state?.consultantDocument === "rut") {
      Inputmask({
        mask: ["x.999.999-K", "x9(.999){2}-K"],
        showMaskOnHover: false,
        showMaskOnFocus: false,
        autoUnmask: true,
        keepStatic: true,
        clearIncomplete: false,
        clearMaskOnLostFocus: false,
        definitions: {
          K: {
            validator: "[0-9|Kk]",
          },
          x: {
            validator: "[1-9]",
          },
        },
      }).mask(selector);
      return;
    }

    if (this.state?.consultantDocument === "cedulaIdentificacion") {
      Inputmask({
        mask: ["999999999-9"],
        showMaskOnHover: false,
        keepStatic: false,
        showMaskOnFocus: false,
        autoUnmask: true,
        clearIncomplete: false,
        clearMaskOnLostFocus: true,
      }).mask(selector);
    }

    if (this.state?.consultantDocument === "ppt") {
      Inputmask({
        mask: "9999999999",
        showMaskOnHover: false,
        keepStatic: false,
        showMaskOnFocus: false,
        autoUnmask: true,
        clearIncomplete: false,
        clearMaskOnLostFocus: true,
      }).mask(selector);
    }

    if (this.state?.consultantDocument === "dniArg") {
      Inputmask({
        mask: ["x.999.999", "x9.999.999"],
        showMaskOnHover: false,
        showMaskOnFocus: false,
        autoUnmask: true,
        keepStatic: true,
        clearIncomplete: false,
        clearMaskOnLostFocus: false,
        definitions: {
          K: {
            validator: "[0-9|Kk]",
          },
          x: {
            validator: "[1-9]",
          },
        },
      }).mask(selector);
    }
  }

  private handleInputMask(country: Country): void {
    const selector = document.getElementById("username") as HTMLInputElement;
    Inputmask("", {
      placeholder: this.props.i18n.getUsernamePlaceholder(
        country,
        (localStorage.getItem("selectedOption") as string) ??
          (this.state?.consultantDocument as string)
      ),
      clearMaskOnLostFocus: true,
      showMaskOnHover: false,
      showMaskOnFocus: false,
    })
      .mask(selector)
      .remove();
  }
}
