import { RouteComponentProps } from "react-router-dom";
import { validate } from "class-validator";
import { HostedUIQueryParams } from "./hosted-ui-query-params";
import { Country } from "../interfaces/country";
import { Language } from "../interfaces/language";
import { isRouteSocialSignIn } from "./query-params-utils";

const returnLowerCase = (word?: string) => {
  return word ? word.toLowerCase() : undefined;
};

const returnEmptyString = (word?: string) => {
  return word ? word : "";
};

const returnUndefined = (word?: string) => {
  return word ? word : undefined;
};

const queryParams: [string, Function][] = [
  ["country", returnLowerCase],
  ["company", returnEmptyString],
  ["language", returnLowerCase],
  ["redirect_uri", returnEmptyString],
  ["client_id", returnEmptyString],
  ["username", returnEmptyString],
  ["code", returnEmptyString],
  ["session", returnEmptyString],
  ["destination", returnEmptyString],
  ["previousPage", returnEmptyString],
  ["error_description", returnEmptyString],
  ["sso", returnEmptyString],
  ["maintenance", returnEmptyString],
  ["response_type", returnUndefined],
  ["code_challenge", returnUndefined],
  ["code_challenge_method", returnUndefined],
  ["state", returnUndefined],
  ["captcha_bypass", returnUndefined],
];
export class HostedUIQueryValidator {
  constructor(private readonly props: RouteComponentProps) {}

  public async validateOrReject(): Promise<void> {
    if (isRouteSocialSignIn()) {
      return;
    }
    const params = this.getParams();
    const errors = await validate(params);
    if (errors.length > 0) {
      const messages = [];
      for (const error of errors) {
        if (error.constraints !== undefined) {
          for (const key of Object.keys(error.constraints)) {
            messages.push(error.constraints[key]);
          }
        }
      }
      throw new Error(messages.join(", "));
    }
  }

  public getParams(): HostedUIQueryParams {
    const params = new HostedUIQueryParams();
    const objParams = params as unknown as Record<string, string>;
    for (const element of queryParams) {
      const key = this.toCamelCase(element[0]);
      const value = this.getQueryParamByName(element[0]);
      objParams[key] = element[1](value);
    }
    objParams["country"] = objParams["country"] as Country;
    objParams["language"] = objParams["language"] as Language;

    return objParams as unknown as HostedUIQueryParams;
  }

  private getQueryParamByName(name: string) {
    const searchInfo = this.props.location.search;
    const query = new URLSearchParams(searchInfo);
    return query.get(name);
  }

  private toCamelCase(name: string): string {
    return name
      .split("_")
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join("");
  }
}
