import React, { useEffect, useState } from "react";
import styles from "../styles/styles";
import { Logo as LogoNaturaDS } from "@naturacosmeticos/natds-web";
import { logoSVG } from "src/assets/natura-logo-cb-latam";
import {
  getCountry,
  getLanguage,
  getURLSearchParams,
} from "src/utils/query-params-utils";
import { eloIsEnabled } from "src/utils/elo-utils";

export const Logo = (): React.JSX.Element => {
  const [useBModel, setUseBModel] = useState(false);
  const [isEloEnabled, setIsEloEnabled] = useState(false);

  useEffect((): void => {
    const urlSearchParams = getURLSearchParams();
    const country = getCountry(urlSearchParams);
    const language = getLanguage(urlSearchParams);
    const isEloEnabled = eloIsEnabled(country);
    setUseBModel(isEloEnabled && language === "pt");
    setIsEloEnabled(isEloEnabled);
  }, []);

  return (
    <div className="row" style={styles.logoView}>
      <div style={styles.logo}>
        {isEloEnabled ? (
          useBModel ? (
            <LogoNaturaDS model="b" />
          ) : (
            <img
              src={logoSVG}
              className="logoLatam"
              style={styles.logoLatam}
              aria-label="logo"
              alt="Latam Logo"
            />
          )
        ) : (
          <LogoNaturaDS model={useBModel ? "b" : "a"} />
        )}
      </div>
    </div>
  );
};
